import React from 'react';
import PropTypes from 'prop-types';
import marked from 'marked';

export const HTMLContent = ({ content, className }) => {
  let markup = '';
  const renderer = new marked.Renderer();
  const linkRenderer = renderer.link;

  renderer.link = (href, title, text) => {
    const html = linkRenderer.call(renderer, href, title, text);
    return html.replace(/^<a /, '<a target="_blank" rel="nofollow" ');
  };

  if (!!content) {
    markup = marked(content, { renderer, sanitize: false });
  }

  return (
    <div
      className={`${className} html-content`}
      dangerouslySetInnerHTML={{ __html: markup }}
    />
  );
};

const Content = ({ content, className }) => (
  <div className={`${className} html-content`}>{content}</div>
);

Content.propTypes = {
  content: PropTypes.node,
  className: PropTypes.string,
};

HTMLContent.propTypes = Content.propTypes;

export default Content;
